.auiOverlayMask {
  .modal {
    min-width: 530px;
    border-radius: 4.8px;
    box-shadow: 0 18px 30px 10px rgba(0, 0, 0, 0.15);
    background: #ffffff;

    .modal-header {
      line-height: 24px;
      padding: 16px 24px 32px;
      font-size: 19px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
      }
    }

    .modal-content {
      line-height: 24px;
      padding: 0 48px 24px 24px;
      border-bottom: 1px solid #e8e8ed;
    }

    .modal-footer {
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;

      .cancel-btn {
        height: 34px;
        margin-right: 16px;
      }

      .low-btn {
        height: 34px;
      }
    }
  }
}
