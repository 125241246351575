@import "src/scss/colors.scss";

.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: $primary-color;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.react-root-value-class {
  padding-left: 0 !important;
}
.no-access-admin {
  text-align: center;
  font-size: 20px;
  color: darkgrey;
  padding: 71px 0 0 10%;
  margin-top: 20%;
}
.css-1hwfws3 {
  position: unset !important;
}

.container-highchart-height {
  height: 450px !important;
}

.toast-error {
  background: #fe354d !important;
  border: #fe354e solid 2px !important;
  color: #ffffff !important;
}
