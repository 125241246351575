@import "src/scss/colors.scss";

.navbar-link {
  padding: 10% 0;
}

.navbar-inverse {
  .navbar-link {
    color: $primary-color !important;
  }

  .navbar-brand {
    color: $primary-color !important;
  }
}

.navigation-item {
  font-size: 17px;
  color: $primary-color;
}

.nav {
  li {
    margin-right: 20px;
    border: 3px solid transparent;
  }
}

.nav-item {
  .active {
    border-bottom: 3px solid #6ad1c3;
  }
}

.logo {
  position: relative;
  left: 70px;
  font-size: 25px !important;
}

.header {
  display: inline-block !important;
  font-size: 25px;
  margin-left: 5.3rem;
  font-family: sans-serif;
  font-weight: 400;
  color: #676666;
}

.logout-icon {
  color: $primary-color;
  font-size: 20px;
  margin-right: 20px;
  margin-top: 16px;
  cursor: pointer;
}

// NEW STYLES --------------------------------

// colors
$top-nav-bg-color: #333333;
$top-nav-link-color: #ffffff;
$top-nav-avatar-border-color: #ffffff;
$top-nav-avatar-name-color: #ffffff;

// z-index
$top-nav-z-index: 1000;

@mixin page-nav-link {
  text-decoration: none;
  text-align: center;
  line-height: 24px;
  color: $top-nav-link-color;
  margin: 0 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: "SFProText-Regular", "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: nowrap;
}

.insightLibrarycomponent {
  .page-nav-wrapper {
    min-height: 56px;
  }

  .page-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 48px;
    min-height: inherit;
    width: 100%;
    background-color: $top-nav-bg-color;
    z-index: $top-nav-z-index;
  }

  .page-nav__logo {
    display: block;
    width: 200px;
    height: 45px;
    max-width: 200px;
    max-height: 45px;
    margin-bottom: 4px;
    margin-right: 5px;
    background-image: url("~assets/dark-horizontal.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .page-nav__logo-wrapper.auiFlexGroup {
    flex-grow: 0;
  }

  .page-nav__logo-text.auiTitle {
    font-family: "SFProText-Semibold", "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 19px;
    color: #ffffff;
    line-height: 24px;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: normal;
  }

  .page-nav__logo-text {
    .logo-text {
      display: none;
    }
  }

  .page-nav__env-label-wrapper {
    align-self: flex-end;
  }

  .page-nav__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .page-nav__popover {
      .auiPopover__anchor {
        .page-nav__popover-button {
          min-width: 30px;
          min-height: 40px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }

  .page-nav__bell-wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
  }

  .page-nav__bell-wrapper:hover {
    cursor: pointer;
  }

  .page-nav__badge {
    position: absolute;
    top: 3px;
    right: 4px;
    color: #fff;
    background-color: red;

    display: inline-block;
    min-width: 1.6em;
    padding: 0.2em;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .page-nav__search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
  }

  .page-nav__search {
    display: inline-block;
    background: url("~assets/assets/icons/magnifyingglass.white.svg") no-repeat;
    background-size: 100% 100%;
    height: 16px;
    width: 16px;
  }

  .page-nav__bell {
    display: inline-block;
    background: url("~assets/assets/icons/bell.white.svg") no-repeat;
    background-size: 100% 100%;
    height: 16px;
    width: 14px;
  }

  .page-nav__square-grid-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
  }

  .page-nav__square-grid {
    display: inline-block;
    background: url("~assets/assets/icons/square.grid.3x3.fill.white.svg") no-repeat;
    background-size: 100% 100%;
    height: 16px;
    width: 16px;
  }

  .auiLink.page-nav__link,
  .page-nav__link {
    @include page-nav-link();
  }

  .page-nav__link--active {
    @include page-nav-link();
    font-weight: 700;
  }

  .page-nav__user-avatar.auiAvatar {
    color: $top-nav-avatar-name-color;
    border: 1px solid $top-nav-avatar-border-color;
    height: 26px;
    width: 26px;
    line-height: 20px;
    font-size: 11px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-nav__links {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-end;
  }

  .question-mark-icon {
    margin: 10px;
  }

  .log-out-icon {
    margin: 10px;

    .auiOverflowMenuItem:last-child:focus {
      border: none;
      border-radius: 4.8px;
    }
  }

  .page-nav__popover-list {
    .page-nav__popover-list-item-link {
      display: block;
      box-sizing: border-box;
      padding: 8px;
      text-align: left;
      color: #333;
      min-height: 36px;
      border: 2px solid transparent;
    }

    .page-nav__popover-list-item-link:hover {
      background: #f5f5f7;
      border-radius: 4.8px;
    }
  }

  .auiOverflowMenuItem.page-nav__popover-menu-item:hover {
    border-radius: 4.8px;
  }
}
