@import "@apple/aero-ui/dist/aui_theme_light.css";
@import "src/scss/colors.scss";
@import "src/scss/display.scss";
@import "./Components/Header/header";
@import "../node_modules/@apple/aero-ui/lib/global_styling/variables/colors";
@import "react-toastify/dist/ReactToastify.css";

#app {
  min-width: 1250px !important;
}

input {
  &[type="text"] {
    border-color: lightgray;
    border-style: solid;
    border-radius: 6px;
    border-width: 1px;
  }
}
.modal-footer {
  clear: both;
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.selectedTab {
  font-size: 21px;
}
.fa-search {
  content: $display-content-code;
  color: gray;
  font-size: 25px !important;
  position: absolute;
  left: -10px;
}

.toggle-css {
  .left-radio {
    margin-right: 3px;
  }

  .right-radio {
    margin-left: 6px;
    margin-right: 3px;
  }
}

.add-document-button {
  float: right;
  border-radius: 7px;
  border: 2px solid $button-color;
  background: none;
  color: $button-color;
  outline: none;
}

.document-section {
  margin: 5% 0%;
  height: fit-content;
}

.css-d8oujb {
  display: none;
}

@media only screen and (max-width: 1768px) {
  .admin-sidebar {
    width: 202px;
  }
}

.drop {
  display: inline-block;
  position: relative;
  width: 100%;
  top: 1vh;
}

.upload-drop-target {
  display: inline-block;
  background: #6ad1c3;
  height: 80vh;
  padding: 25% 23%;
  border: 2px solid $primary-color;
  width: 100%;
  input {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
  }
}

.modalDialog {
  margin: 30px 0 !important;
  min-width: 700px;
  text-align: center;
  color: $primary-color;
  font-size: 20px;
}

.modalDialogConfirmation {
  display: inline-block;
  top: 40%;
  left: 40%;
}

@media (max-width: 991px) {
  .modalDialog {
    width: 82% !important;
    position: absolute;
    top: 105px;
    left: 250px;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .modalDialog {
    width: 82% !important;
    position: absolute;
    top: 105px;
    left: 250px;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .modalDialog {
    width: 80% !important;
    position: absolute;
    max-width: none;
    left: 250px;
  }
}

@media (min-width: 1560px) {
  .modalDialog {
    width: 83% !important;
    position: absolute;
    max-width: none;
    left: 250px;
  }
}

@media (min-width: 1756px) {
  .modalDialog {
    width: 82% !important;
    position: absolute;
    max-width: none;
    left: 17%;
  }
}

@media (max-width: 758px) {
  .modalDialogConfirmation {
    position: fixed;
    top: 180px;
    left: 300px;
    z-index: 1050;
  }
}

.css-1oubc3t {
  min-height: 30px !important;
  height: 0 !important;
}

.css-5h5nzh {
  min-height: 30px !important;
  height: 0 !important;
}

.delRow {
  select {
    display: inline-block;
    margin: 0;
    background: #fff;
    color: #333;
    border: 1px solid #ccc;
    outline: none;
    appearance: none;
    width: 100%;
    padding: 0 5px;
    height: 35px;
    font-size: 14px;
    cursor: pointer;
    option {
      padding: 8px 5px;
      background: none;
    }
  }
  label {
    position: relative;
    &::after {
      content: $display-content-sign;
      font: 14px "Consolas", monospace;
      color: #333;
      transform: rotate(90deg);
      right: 8px;
      top: 0;
      padding: 0 0 2px;
      border-bottom: 1px solid #ddd;
      position: absolute;
      pointer-events: none;
    }
    &::before {
      display: block;
      content: $display-no-content;
      right: 6px;
      top: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      position: absolute;
      pointer-events: none;
    }
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// TODO: Fix in @apple/aero-ui/dist/aui_theme_light.css 3 font-face declarations line 392 - 411
@font-face {
  font-family: "SF Pro Text";
  src: url("@apple/aero-ui/dist/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("@apple/aero-ui/dist/SF-Pro-Text-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("@apple/aero-ui/dist/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.insightLibrarycomponent {
  margin: 0;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .app-layout-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .full-height {
    height: 100vh;
    display: block;
  }

  .auiToolTip {
    color: #0b0b0b;
    padding: 15px;
    min-width: fit-content;

    &.auiToolTip-280 {
      min-width: initial;
      max-width: 280px !important;
    }

    .auiText.auiText--small {
      line-height: 1.3rem;

      p {
        margin-bottom: 1rem;
      }
    }
  }

  .auiModalFooter {
    box-shadow: none;
  }

  .details-title.auiTitle {
    color: #000000;
    font-size: 23px;
    margin: 8px 0 !important;
    font-weight: 600;
    display: flex;

    font-family: "SFProDisplay-Regular", "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 24px;

    .title-text {
      padding-right: 5px;
    }
  }

  .auiBreadcrumbs {
    font-size: 16px;
    height: 24px;
  }

  .aero-dropdown {
    .auiFormControlLayout {
      position: relative;

      &::before {
        width: 24px;
        height: 24px;
        content: url("~assets/assets/icons/magnifying.glass.grey.svg");
        position: absolute;
        z-index: 1;
        left: 7px;
        top: 7px;
      }

      .auiFieldText {
        padding-left: 30px;
      }
    }
  }

  // Show loader in mid of the window
  .auiLoadingSpinner.spinner-in-mid {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .no-link-background {
    &.auiLink:hover::before {
      display: none;
    }
  }

  .auiFieldText:disabled {
    background-color: hsl(0, 0%, 95%);
  }

  .auiFieldText-label.auiFieldText-label--disabled {
    color: #000;
  }

  // START Switch Toggle Generic font-style
  // Default state
  .auiSwitch .auiSwitch__input:not(:checked):not(:disabled) ~ .auiSwitch__body {
    background-color: #d6d6d6;
  }

  // unchecked and disabled
  .auiSwitch .auiSwitch__input:disabled ~ .auiSwitch__body {
    background-color: #d6d6d6;

    .auiSwitch__thumb {
      background-color: #e8e8ed;
    }
  }

  // checked and disabled
  .auiSwitch .auiSwitch__input:checked:disabled ~ .auiSwitch__body {
    background-color: #d5ebf8;

    .auiSwitch__thumb {
      background-color: #fff;
    }
  }

  // END Switch Toggle Generic font-style

  .auiToast {
    &--danger {
      background-color: $auiColorRedSecondary;
      border: 1px solid $auiColorRedSecondary;
      box-shadow: -6px 0 0 0 #f9cccc;
      -webkit-box-shadow: -6px 0 0 0 #f9cccc;
    }

    &--success {
      border: 1px solid $auiColorGreenSecondary;
      background-color: $auiColorGreenSecondary;
      -webkit-box-shadow: -6px 0 0 0 #ccefd2;
      box-shadow: -6px 0 0 0 #ccefd2;
    }

    &--primary {
      border: 1px solid $auiColorBlueSecondary;
      background-color: $auiColorBlueSecondary;
      -webkit-box-shadow: -6px 0 0 0 #b2d4f6;
      box-shadow: -6px 0 0 0 #b2d4f6;
    }
  }
}
