.insightLibrarycomponent {
  .no-access-page {
    min-height: calc(100vh - 120px);

    &__image {
      width: 37px;
      height: 52px;
      background-image: url("~assets/assets/icons/access.manager.thumb.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__heading.auiText {
      font-size: 19px;
      color: #000000;

      span {
        font-weight: 600;
      }
    }
  }
}
