@import "./variables.scss";

.insightLibrarycomponent {
  .page-footer-wrapper {
    min-height: 30px;

    .page-footer {
      display: flex;
      position: fixed;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      min-height: inherit;
      width: 100%;
      border-top: 1px solid $footer-border-top-color;
      background-color: $footer-bg-color;
      z-index: $footer-z-index;
    }
  }
}
