.insightLibrarycomponent {
  .AuiToastWrap {
    padding: 19px;
    align-items: center;
  }

  // .react-toastify--custom {
  //   .AuiToastWrap--custom {
  //     padding: 10px;

  //     .auiToastHeader--withBody {
  //       margin-bottom: 0px;
  //     }

  //     .auiToast__closeButton {
  //       top: 25px;
  //     }
  //   }

  //   .Toastify__close-button.Toastify__close-button--light {
  //     display: none;
  //   }
  // }

  .Toastify__toast-container {
    width: 400px;

    &.Toastify__toast-container--top-right {
      top: 0.5rem;
      right: 0.5rem;
    }

    .auiToast {
      padding: 10px;

      .auiToastHeader--withBody {
        margin-bottom: 5px;
      }
    }

    .Toastify__toast {
      margin-bottom: 8px;
      overflow: initial;
    }
  }

  .app-layout-wrapper {
    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes fadeout {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fadeout {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .fade-in {
      animation: fadein 0.5s linear forwards;
    }

    .fade-out {
      animation: fadeout 0.5s linear forwards;
    }
  }
}
