.insightLibrarycomponent {
  .logout-page {
    min-height: 100vh;

    &__image {
      width: 52px;
      height: 52px;
      background-image: url("~assets/assets/icons/rectangle.portrait.and.arrow.right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__heading.auiText {
      font-size: 19px;
      color: #000000;

      span {
        font-weight: 600;
      }
    }
  }
}
